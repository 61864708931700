.footerMainContainer {
    background-color: #EEF1F6;
    padding: 4rem 6rem;
}

.footerMainContainer_topContainer {
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
}

.footerMainContainer_topContainer>div {
    flex: 1
}

.footerMainContainer_topContainer_grooksLogoAndSocialMediaContainer>img {
    width: 10rem;
}

.footerMainContainer_topContainer>div>p,
.footerMainContainer_bottomContainer>p {
    font-size: 1rem;
    color: #505056;
}

.footerMainContainer_topContainer>div>h6 {
    font-size: 1rem;
    color: black;
    font-weight: bold;
}

.socialMedia_handleCotainer {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.socialMedia_handleCotainer>img {
    cursor: pointer;
    width: 2rem;
}

.policyBtn_container{
    display:  flex;
    gap: 1rem;
    margin-top: 1rem;
    /* flex-wrap: wrap; */
}

.footerMainContainer_bottomContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6rem;
}

.footerMainContainer_bottomContainer>button,
.policyBtn_container>button {
    border-width: 0;
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    color: black;
}
.policyBtn_container>button{
    font-size: 0.8rem;
}

.footerMainContainer_bottomContainer>button>img {
    width: 1.2rem;
    cursor: pointer;
}

/* TABLET */
@media screen and (max-width:900px) {
    .footerMainContainer {
        padding: 4rem 1.5rem;
    }

    .footerMainContainer_topContainer {
        gap: 2rem;
    }

    .footerMainContainer_topContainer_grooksLogoAndSocialMediaContainer>img {
        width: 7rem;
    }

    .footerMainContainer_topContainer>div>p,
    .footerMainContainer_bottomContainer>p {
        font-size: 0.8rem;

    }

    .footerMainContainer_topContainer>div>h6 {
        font-size: 0.8rem;
    }

    .footerMainContainer_bottomContainer {
        margin-top: 3rem;
    }

    .footerMainContainer_bottomContainer>button{
        font-size: 1rem;
    }
    .policyBtn_container>button{
        font-size: 0.6rem;
    }
}

@media screen and (max-width:600px) {
    .footerMainContainer_bottomContainer {
        flex-direction: column;
        gap: 2rem;
    }

    .footerMainContainer_bottomContainer>button,
    .policyBtn_container>button {
        align-self: flex-start;
    }

    .footerMainContainer_bottomContainer>p {
        text-align: center;
    }
}