.modalContainer {
    background-color: white;
    position: absolute;
    bottom: 0;
    padding: 1rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}   

.tradeSuccesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tradeSuccesContainer > text{
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
}
.tradeSuccesContainer > p{
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
}