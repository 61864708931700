.downloadButtonContainer {
    border-width: 0;
    background-color: white;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    font-weight: 600;
    gap: 0.5rem;
    cursor: pointer;
    color: white;
    border-radius: 50rem;
    background-color: #5377D6;
    
}

.downloadButtonContainer>img {
    width: 1.2rem;
    cursor: pointer;
    
}

@media screen and (max-width:600px) {
    .downloadButtonContainer {
        font-size: 0.9rem;
    }

    .downloadButtonContainer>img {
        width: 0.9rem;
    }
}