.buttonMainContainer {
    border-radius: 999px;
    text-align: center;
    padding: 0.7rem 1rem;
    background-color: rgba(1, 71, 146, 1);
    width: 100%;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    border-width: 0;
}