.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(175, 175, 175);   
    width: 100%;
}

.mobileView {
    /* height: 99vh; */
    height: 100svh;
    width: 25Vw;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
}

.contentContainer {
    height: 100%;
    /* width:  100%; */
    padding: 0 1rem;
    /* position: relative; */
}

.appName {
    text-align: center;
    color: rgba(1, 71, 146, 1);
    margin: 1rem 0;
}

.apiCallFailMsg{
    text-align: center;
    font-size: 1rem;
}

.questionImage {
    width: 100%;
    /* height: 12rem; */
    background-color: rgb(214, 214, 214);
    border-radius: 1rem;
    margin: 1rem 0;
    height: auto;
    max-height: 12rem;
}

.entry_fees_container {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

.entry_fees_text {
    font-size: 1rem;
    color: rgb(118, 118, 118);
    font-weight: 600;
}

.entry_fees_free_text {
    color: rgba(1, 71, 146, 1);
    font-weight: bold;
    font-size: 1.2rem;
}

.seprator {
    height: 0.5rem;
    background-color: rgb(218, 217, 217);
    margin: 1rem -1rem;
}

.source_of_truth_container {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}

.source_of_truth {
    font-weight: 600;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
}

@media screen and (max-width:1200px){
    .mobileView{
      width: 35vw;
    }
  }
  @media screen and (max-width:900px){
    .mobileView{
      width: 45vw;
    }
  }
  @media screen and (max-width:600px){
    .mobileView{
      width: 100%;
    }
  }