.customModal_mainContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    /* overflow: none; */
}

.transparent_container{
    background-color: rgb(0, 0, 0,0.5);
    width: 100%;
    height: 100%;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 20vw; */
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* overflow: ; */
}

.modal {
    /* position: fixed; */
  background: white;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  /* max-width: 400px; */
  width: 25vw;

}

.modal-content {
  /* margin-bottom: 15px; */
}

.close-button {
  background: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 3px;
  cursor: pointer;
}

  @media screen and (max-width:1200px){
    .modal{
      width: 35vw;
    }
  }
  @media screen and (max-width:900px){
    .modal{
      width: 45vw;
    }
  }
  @media screen and (max-width:600px){
    .modal{
      width: 100%;
    }
  }