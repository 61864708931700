/* @import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;800&display=swap'); */

.howItWorksStep_mainContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    background: rgb(241, 244, 249);
}

.renderStepContainer {
    display: flex;
    align-items: center;
    position: relative;
    width: 50%;
    margin-left: 1rem;
/* border: 2px solid red; */
}

.renderStepContainer:nth-child(2) {
    align-self: flex-end;
    margin-right: 1rem;
    margin-left: 0;
    flex-direction: row-reverse;
}

.renderStepContainer:nth-child(2)>.cardContainer {
    align-items: flex-start;
}

.renderStepContainer:nth-child(2)>.cardContainer>.stepDescription {
    text-align: left;
}

.renderStepContainer:nth-child(2)>.cardContainer>.stepCountInsideCard {
    right: 0.5rem;
    left: unset;
}

.renderStepContainer:nth-child(2)>.dashContainer {
    flex-direction: row-reverse;
}

.renderStepContainer:nth-child(2)>.dashContainer>.dashContainer_horizontalArrowContainer {
    flex-direction: row-reverse;
}

.cardContainer {
    width: 65%;
    background-color: #A5F7AE;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0.6rem 1.5rem;
}

.stepIcon {}

.stepTitle {
    font-size: 1.2rem;
}

.stepDescription {
    text-align: right;
    font-size: 0.85rem;
    color: #1E0702;
    width: 80%;
    font-size: 0.8rem;
    font-weight: 500;
}

.stepCountInsideCard {
    position: absolute;
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Jost', sans-serif;
    color: #EBEBEB;
    left: 0.5rem;
}

.dashContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
    position: relative;
}

.dashContainer_horizontalArrowContainer {
    display: flex;
    align-items: center;
}

.dashContainer_horizontalArrowContainer>p,
.dashContainer_verticalArrowContainer>p {
    font-size: 1.5rem;
    color: #CECECE;
}

.dashContainer_horizontalArrowContainer_dash {
    width: 4.37rem;
    border-bottom: 2px dashed #CECECE;
}

.dashContainer_verticalArrowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2rem;
}

.dashContainer_stepCountContainer {
    width: 2rem;
    height: 2rem;
    background-color: #5A5A5A;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 90rem;
    position: absolute;
}

.dashContainer_stepCountContainer>p {
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

.dashContainer_verticalArrowContainer_dash {
    border-left: 2px dashed #CECECE;
    /* height: 175px; */
    height: 12.5rem;
    display: flex;
}

/* TABLET */
@media screen and (max-width:1100px) {
    .howItWorksStep_mainContainer {
        width: 100%;
    }
}

/* MOBILE */
@media screen and (max-width:600px) {
    .howItWorksStep_mainContainer {
        align-items: center;
    }

    .renderStepContainer {
        flex-direction: column-reverse;
        margin-left: 0;
        margin-right: 0;
        width: 90%;
        /* margin: auto; */
    }

    .renderStepContainer:nth-child(2) {
        align-self: unset;
        margin-right: 0;
        /* margin: auto; */
        flex-direction: column-reverse;
    }

    .cardContainer {
        width: 100%;
    }

    .dashContainer_horizontalArrowContainer {
        display: none;
    }

    .renderStepContainer:nth-child(2)>.dashContainer>.dashContainer_horizontalArrowContainer {
        flex-direction: unset;
    }

    .dashContainer_verticalArrowContainer_dash {
        height: 8rem;
    }
}