* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

.App {}

.title {
  font-size: 1.4rem;
  font-weight: bold;
}

.heading {
  font-size: 2.4rem;
  color: #3F3F3F;
  font-weight: bold;
  text-align: center;
}

.appPaddingHorizontal{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.centerLoading {
  border: 0.2rem solid #f3f3f3;
  border-top: 0.2rem solid  rgba(1, 71, 146, 1);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: loading 2s linear infinite;
  margin: auto;
  margin-top: 1rem;
  /* display: flex; */
  /* align-self: flex-end; */
  /* margin: 20% auto; */
}

@keyframes loading {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

.borderRed {
  border: 2px solid red
}

.borderBlue {
  border: 2px solid blue
}

.borderGreen {
  border: 2px solid green
}

.borderYellow {
  border: 2px solid yellow
}

@media screen and (max-width:900px) {
  .heading {
    font-size: 1.7rem;
  }
}
@media screen and (max-width:600px) {
  .heading {
    font-size: 1.2rem;
  }
}