.label {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 0.2rem;
}

.labelInput {
    /* width: 100%; */
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-width: 2px;
    border-radius: 0.5rem;
}

.error{
    font-size: 0.9rem;
    color: red;
    height: 1rem;
}