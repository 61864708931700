.header_mainContainer {
    position: fixed;
    width: 100vw;
    z-index: 9999;
}

.header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
}



.appLogo {
    width: 10rem;
}

.navigationBar_links>ul,
.navigationBar_linkMobile>ul {
    display: flex;
    gap: 1rem;
    /* align-items: flex-start; */
}

.navigationBar_links>ul>li,
.navigationBar_linkMobile>ul>li {
    list-style: none;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    /* padding-left: 3.64vw; */
}

.navigationBar_linkMobile>ul {
    justify-content: flex-start;
}

.navigationBar_links>ul>li>p,
.navigationBar_linkMobile>ul>li>p {
    font-size: 1.2rem;
    font-weight: 600
        /* padding-left: 3.64vw; */
}

.navigationBar_links>ul>li>Link {
    /* padding-left: 3.64vw; */
}


/* ----------Mobile View--------- */
.navigationBar_leftComponent {
    display: none;
}

.navigationBar_leftComponent>img {
    width: 25px;
}

.navigationBar_linkMobile {
    background-color: white;
    position: fixed;
    right: 0;
    box-shadow: -10px 11px 22px -11px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -10px 11px 22px -11px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -10px 11px 22px -11px rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 2rem;
}

.navigationBar_linkMobile>ul {
    /* min-height: 40%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 1rem;
}

.navigationBar_linkMobile>ul>li {
    gap: 1rem;
}

.navigationBar_linkMobile>ul>li>img {
    width: 1.2rem;
    height: 1.2rem;
}

.navigationBar_leftComponent {
    padding-right: 2rem;
}

@media (max-width: 769px) {
    .header_container {
        width: 100%;
        padding: 0.2rem 1.5rem;

    }

    .appLogo {
        width: 7rem;
    }

    .navigationBar_leftComponent {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .navigationBar_links {
        display: none;
    }
}


/*------------------------------ TABLET ------------------------------*/
@media screen and (max-width:900px) {}

/*-------------------------- MOBILE STYLE --------------------------*/
@media screen and (max-width:600px) {}