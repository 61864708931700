/*--------------------------- HERO SECTION ---------------------------*/
.headerAndHeroContainer {
    background-color: white;
}

.heroContainer {
    min-height: 100lvh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1.5rem;
}

.heroContainer_textAndImgContainer {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.heroContainer_textAndImgContainer>div {
    margin-left: 4rem;
}

.heroContainer_textAndImgContainer>div>p {
    font-size: 2rem;
    font-weight: bold;
    background: linear-gradient(to right, #173351, #5B3DAE);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.heroContainer_textAndImgContainer>div>h1 {
    font-size: 5rem;
    font-weight: bold;
    background: linear-gradient(180deg, #E35555 20%, #7A55E3 60%, #0E012B 50%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-family: 'Podkova', serif;
}

.heroContainer_textAndImgContainer>div>h2 {
    font-size: 2.5rem;
}

.heroContainer_bottomText {
    padding: 0 20vh;
    text-align: center;
    margin-top: 2.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #6F6A80;
}

/*---------------------- HOW IT WORKS SECTION ----------------------*/
.howItsWorksContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(241, 244, 249);
    background: linear-gradient(90deg, rgba(241, 244, 249, 1) 0%, rgba(241, 244, 249, 1) 100%);
    padding: 6rem 1.5rem;
}

.howItsWorksContainer_subHeading {
    font-weight: 600;
    color: #6F6A80;
    text-align: center;
    /* width: 30%; */
    width: 30rem;
    align-self: center;
    margin-top: 0.2rem;
    margin-bottom: 0.8rem;
    font-size: 0.9rem;
}

.grooksFeaturesContainer {
    padding: 4rem 1.5rem;
    position: relative;
}

.grooksFeaturesContainer_backgroundClip {
    /* clip-path: polygon(0% 65%, 17% 79%, 30% 57%, 42% 72%, 48% 43%, 61% 63%, 68% 42%, 78% 59%, 88% 50%, 94% 48%, 99% 33%, 100% 99%, 0% 99%); */
    clip-path: polygon(0% 67%, 14% 83%, 28% 68%, 39% 76%, 53% 55%, 68% 70%, 74% 39%, 83% 61%, 93% 54%, 100% 45%, 100% 100%, 0% 100%);
    position: absolute;
    background-color: #EFF2F7;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.grooksFeaturesContainer_headingContainer {
    display: flex;
}

.grooksFeaturesContainer_headingContainer>h6 {
    text-align: left;
}

.grooksFeaturesContainer_listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    margin-top: 3rem;
}

.grooksFeaturesContainer_listContainer_fetaureGroup {
    display: grid;
    /* grid-template-columns: 60rem 60rem; */
    grid: auto auto / auto auto;
    gap: 4rem;
    justify-content: space-between;
    align-self: center;
    width: 90%;
}

.grooksFeaturesContainer_listContainer_fetaureGroup>div:nth-child(3),
.grooksFeaturesContainer_listContainer_fetaureGroup>div:nth-child(4) {
    width: 80%;
}

.grooksFeaturesContainer_listContainer_fetaureGroup>div:nth-child(3) {
    margin-left: auto;
}

/*------------------------ PAYMENT SECTION  ------------------------*/
.paymentContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6rem;
    padding: 4rem;
}

.paymentContainer>div {
    width: 40%;
}

.paymentContainer>div>h6 {
    text-align: left;
}

.paymentContainer_description {
    font-size: 1rem;
    font-weight: 500;
    color: black;
    margin-top: 1rem;
}

.paymentContainer>img {
    height: 30rem;
}

/*------------------------- REVIEWS SECTION -------------------------*/
.reviewsSectionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4rem;
    position: relative;
}

.reviewsSectionContainer>h6 {
    width: 40%;
    margin-bottom: 2.5rem;
}

.reviewsSectionContainer_reviewsContainer {
    display: grid;
    grid-template-columns: 22rem 22rem 22rem;
    gap: 1.5rem;
}

.reviewsSectionContainer>img {
    position: absolute;
    z-index: -1;
}

/*--------------------------- LARGE SCREEN ---------------------------*/
@media screen and (max-width:1200px) {

    /*------------------------- HERO SECTION -------------------------*/
    .heroContainer_textAndImgContainer>div>p {
        font-size: 1.5rem;
    }

    .heroContainer_textAndImgContainer>div>h1 {
        font-size: 3.5rem;
    }

    .heroContainer_textAndImgContainer>div>h2 {
        font-size: 1.8rem;
    }
}

/*------------------------------ TABLET ------------------------------*/
@media screen and (max-width:900px) {

    /*------------------------- HERO SECTION -------------------------*/
    .heroContainer {
        text-align: center;
    }

    .heroContainer_textAndImgContainer {
        flex-direction: column;
        gap: 0;
    }

    .heroContainer_textAndImgContainer>div {
        margin-left: 0;
    }

    .heroContainer_textAndImgContainer>div>h1 {
        font-size: 4rem;
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    .heroContainer_textAndImgContainer>div>h2 {
        font-size: 1.8rem;
    }

    .heroContainer_textAndImgContainer>img {
        width: 55%;
        margin: 1.5rem 0;
    }

    .heroContainer_bottomText {
        padding: 0 0.5rem;
        margin-top: 0;
    }

    /*------------------------- HOW IT WORKS -------------------------*/
    .howItsWorksContainer {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .howItsWorksContainer_subHeading {
        width: 100%;
    }

    /*----------------------- FEATURES SECTION -----------------------*/
    .grooksFeaturesContainer_headingContainer>h6 {
        /* text-align: center; */
    }

    .grooksFeaturesContainer_listContainer_fetaureGroup {
        /* flex-direction: column; */
        gap: 4rem;
        grid: unset;
        width: 100%;
        /* width: 90% !important; */
    }

    /*----------------------- PAYMENT SECTION -----------------------*/
    .paymentContainer {
        flex-direction: column;
        gap: 2rem;
        padding: 5rem 3rem;
    }

    .paymentContainer>div {
        width: 100%;
    }

    .paymentContainer>div>h6 {
        font-size: 2.2rem;
    }

    .paymentContainer_description {
        font-size: 1.2rem;
    }

    .paymentContainer>img {
        height: unset;
        width: 100%;
    }
}

/*-------------------------- MOBILE STYLE --------------------------*/
@media screen and (max-width:600px) {

    /*------------------------ HERO SECTION ------------------------*/
    .heroContainer_textAndImgContainer {
        text-align: left;
        /* padding: 0 1.5rem; */
    }

    .heroContainer_textAndImgContainer>div>p {
        font-size: 1.2rem;
    }

    .heroContainer_textAndImgContainer>div>h1 {
        font-size: 2.5rem;
    }

    .heroContainer_textAndImgContainer>div>h2 {
        font-size: 1.5rem;
    }

    .heroContainer_textAndImgContainer>img {
        width: 80%;
        /* align-self: center; */
    }

    .heroContainer_bottomText {
        font-size: 1rem;
    }

    /*----------------------- FEATURES SECTION -----------------------*/
    .grooksFeaturesContainer_headingContainer>h6 {
        text-align: center;
    }

    .grooksFeaturesContainer_listContainer_fetaureGroup{
        gap: 2rem;
    }

    .grooksFeaturesContainer_listContainer_fetaureGroup>div:nth-child(3),
    .grooksFeaturesContainer_listContainer_fetaureGroup>div:nth-child(4) {
        width: 100%;
    }

    .grooksFeaturesContainer_listContainer_fetaureGroup>div:nth-child(3) {
        margin-left: unset;
    }


    /*----------------------- PAYMENT SECTION -----------------------*/
    .paymentContainer {
        gap: 2rem;
        padding: 3.5rem 1.5rem;
    }

    .paymentContainer>div>h6 {
        font-size: 2rem;
    }

    .paymentContainer_description {
        font-size: 1rem;
    }

}