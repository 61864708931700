.reviewCardComponentContainer{
padding: 1rem;
border-radius: 0.8rem;
background-color: #F0F0F8;
width: 20rem;
}
.reviewCardComponentContainer_msg{
    color: #60697B;
    font-size: 0.9rem;
}
.reviewCardComponentContainer_name{
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.5rem;
}